.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AppLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.copyright-text {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: #6c757d !important;
}
