.add-wrapper {
    display: flex;
    justify-content: center;
    background: #f8f9fa;
    width: 100%;
    padding: 20px;
}

.add-main-card {
    width: 75%;
    box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
}

.add-main-title {
    margin: 25px;
}

.add-block-separator {
    width: 97%;
    align-self: center;
    margin-block: 0;
}

.add-form-wrapper {
    display: flex;
}

.add-form-div {
    display: flex;
    flex-direction: column;
}

.add-single-check-box {
    margin-top: 2.5rem;
}

.add-bottom-block-separator{
    width: 100%;
    align-self: center;
}

.add-error-message {
    font-size: small;
    color: red;
    margin-inline: 20px;
}

.mandatory-message {
    font-size: small;
    margin: 10px;
}


@media screen and (max-width: 480px) {

    .add-main-card {
        width: 100%;
        box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
    }

    .add-form-div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

}