.box-shadow {
    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    width: 35rem;
    height: 25rem;
    padding: 3rem;
    margin: 3rem;
    border-radius: 5px;
}

.logo-login {
    aspect-ratio: auto;
    width: 25rem;
}

.sign-in-label {
    font-size: x-large;
    margin-bottom: 1rem;
}

.input-login {
    width: 29rem !important;
}

.p-inputtext {
    margin-bottom: 1rem !important;
}

@media screen and (max-width: 480px) {

    .login-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: white;
        width: 22rem;
        height: 22rem;
        padding: 1rem;
        margin: 1rem;
        border-radius: 5px;
    }

    .logo-login {
        aspect-ratio: auto;
        width: 15rem;
    }

    .sign-in-label {
        font-size: large;
        margin-bottom: 1rem;
    }

    .input-login {
        width: 18rem;
    }

    .form-login{
        width: 100%;
    }

}