.home-wrapper {
    display: flex;
    justify-content: center;
    background: #f8f9fa;
    width: 100%;
    padding: 20px;
}

.home-main-card {
    width: 75%;
    box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
}

.home-section-wrapper {
    display: flex;
    flex-direction: row;
}

.home-section-card {
    width: 25%;
    text-align: start;
    margin: 10px;
}

.home-card-title {
    font-size: 1.75rem !important;
    font-weight: 500 !important;
    text-decoration: underline !important;
    text-align: start !important;
}

.home-section-item {
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    .home-main-card {
        width: 100%;
        box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
    }
    .home-section-wrapper {
        display: flex;
        flex-direction: column;
    }
    .home-section-card {
        width: 95%;
        text-align: start;
    }
}