
.menu {
    color: Black;
    display: flex;
    justify-content: center;
    background: #f8f9fa;
    padding: 20px;
    width: 100%;
}

.mainCard {
    width: 75%;
    box-shadow: rgba(50, 50, 93, 0.1) 0px 15px 35px, rgba(0, 0, 0, 0.07) 0px 5px 15px;
}

.marginTop15 {
    margin:  15px 15px 0px 15px;
}

.marginBot15 {
    margin:  0px 15px 15px 15px;
}

hr{
    width: 100%;
    alignSelf: 'center';
    marginBlock: 0;
}

.margin3{
    margin: 3px;
}

.margin-left3{
    margin-left: 5px;
}

.vertical-middle{
    vertical-align: middle;
}

.flex-container {
    /* We first create a flex layout context */
    display: flex;

    /* Then we define the flow direction
       and if we allow the items to wrap
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
    flex-direction: row;
    flex-wrap: wrap;

    /* Then we define how is distributed the remaining space */
    justify-content: left;

    padding: 0;
    margin: 4px 4px 4px 0px;
    list-style: none;
    color: #212529;
}

.flex-item {
    padding: 3px;
    text-align: left;
    width: fit-content;
}
.max-content{
    width: max-content;
}

.with-50{
    width:50%;
}

.div-row{
    margin-bottom: 18px;
}

.div-submit{
    margin-top: 18px;
}

.multi-select {
    min-width: 150px;
    max-width: 800px;
}

.date-style {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    appearance: none;
    border-radius: .375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border: 1px solid #ced4da;
}

.horizontal-scroll{
    height: max-content;
    overflow-x: scroll;
}

.red-text{
    color: red;
}

.div-horizontal {
    overflow: auto;
    white-space: nowrap;
}
.table {
    overflow: auto;
    table-layout: auto !important;
}

.row {
    width: calc(99% - 3px);
}


.react-bootstrap-table {
    text-align: center;
}

.flex-container-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    padding: 0;
    margin: 4px 4px 4px 0px;
    list-style: none;
    color: #212529;
}

.flex-container-hour {
    display: flex;
}

@media screen and (max-width: 480px) {
    .flex-container-buttons {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: left;
        padding: 0;
        margin: 4px 4px 4px 0px;
        list-style: none;
        color: #212529;
    }

    .flex-container-hour {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        padding: 0;
        margin: 4px 4px 4px 0px;
        list-style: none;
        color: #212529;
    }

    .mainCard {
        width: 100%;
        box-shadow: rgba(50, 50, 93, 0.1) 0px 15px 35px, rgba(0, 0, 0, 0.07) 0px 5px 15px;
    }
}



