
.div-row{
    margin-bottom: 18px;
}

.flex-item {
    padding: 3px;
    text-align: left;
    width: fit-content;
}

.flex-container {
    /* We first create a flex layout context */
    display: flex;

    /* Then we define the flow direction
       and if we allow the items to wrap
     * Remember this is the same as:
     * flex-direction: row;
     * flex-wrap: wrap;
     */
    flex-direction: row;
    flex-wrap: wrap;

    /* Then we define how is distributed the remaining space */
    justify-content: left;

    padding: 0;
    margin: 4px 4px 4px 0px;
    list-style: none;
    color: #212529;
}

.multi-select {
    min-width: 150px;
    max-width: 800px;
}

.red-text{
    color: red;
}
