.list-table-data {
    display: flex !important;
    justify-content: space-evenly !important;
}

.list-wrapper {
    display: flex;
    justify-content: center;
    background: #f8f9fa;
    width: 100%;
    padding: 20px;
}

.list-main-card {
    width: 75%;
    box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
}

.list-main-title {
    margin: 25px;
}

.list-block-separator {
    width: 97%;
    align-self: center;
    margin-block: 0;
}

.list-filter-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.list-header {
    text-align: center;
}


.list-error-message {
    font-size: small;
    color: red;
    margin-inline: 20px;
}

.list-section-item {
    cursor: pointer;
}

@media screen and (max-width: 480px) {

    .list-main-card {
        width: 100%;
        box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
    }

    .list-filter-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

}