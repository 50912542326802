.refund-wrapper {
    display: flex;
    justify-content: center;
    background: #f8f9fa;
    width: 100%;
    padding: 20px;
}

.refund-main-card {
    width: 75%;
    box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
}

.refund-main-title {
    margin: 25px;
}

.refund-block-separator {
    width: 97%;
    align-self: center;
    margin-block: 0;
}

.refund-form-wrapper {
    display: flex;
    flex-direction: column;
}

.refund-form-div {
    display: flex;
    flex-direction: row;
}
.refund-form-div-date {
    display: flex;
    flex-direction: row;
}

.refund-form-div-date-wrapper {
    display: flex;
    flex-direction: column;
}

.refund-single-check-box {
    margin-top: 2.5rem;
}

.refund-bottom-block-separator{
    width: 100%;
    align-self: center;
}

.refund-error-message {
    font-size: small;
    color: red;
    margin-inline: 20px;
}

.mandatory-message {
    font-size: small;
    margin: 10px;
}

.flex-item {
    padding: 2px;
    text-align: left;
}

.refund-details-wrapper {
    display: flex;
    flex-direction: column;
}

.refund-details-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 1px;
    border-bottom: 1px solid #ced4da;
}

.refund-detail-card-size {
    width: 50%;
}

.refund-detail-card-size2 {
    width: 50%;
    align-self: center;
}

.refund-details-label {
    font-size: large;
    font-weight: bold;
}

.refund-details-value {
    font-size: large;
}


@media screen and (max-width: 480px) {

    .refund-main-card {
        width: 100%;
        box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07);
    }

    .refund-form-div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

}